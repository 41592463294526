import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  colors,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import axios from 'src/utils/axios';
import getImage from 'src/utils/getImage';
import removeUnderscores from '../../utils/removeUnderscores';
import Label from 'src/components/Label';
import GenericMoreButton from 'src/components/GenericMoreButton';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 900
  },
  author: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  tags: {
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
  actions: {
    justifyContent: 'flex-end',
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1),
  },
  soldAmount: {
    paddingLeft: '2%',
    fontSize: '18px',
  },
}));

function TopSellingProducts({ className, ...rest }) {
  const classes = useStyles();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    let mounted = true;

    const fetchProducts = () => {
      axios.get('/api/v1/products/best_sellers').then((response) => {
        if (mounted) {
          console.log(response.data.data);
          setProducts(response.data.data);
        }
      });
    };

    fetchProducts();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader action={<GenericMoreButton />} title="Best Sellers" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar options={{ suppressScrollY: true }}>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection="desc">
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel active direction="desc">
                        Sold
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  {/*<TableCell>Name</TableCell>*/}
                  <TableCell>Name</TableCell>
                  <TableCell>UPC</TableCell>
                  <TableCell>Brand</TableCell>
                  <TableCell>Category</TableCell>
                  {/*<TableCell align="right">Actions</TableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((p) => (
                  <TableRow hover key={p.id}>
                    {/*<TableCell>{p.product.title}</TableCell>*/}

                    <TableCell>
                      {/*<Label*/}
                      {/*    color={colors.green[600]}*/}
                      {/*    key={p.total_sold_count}*/}
                      {/*  >*/}
                      {/*    {p.total_sold_count}*/}
                      {/*  </Label>*/}
                      {p.total_sold_count} units
                    </TableCell>
                    <TableCell>
                      <div className={classes.author}>
                        <Avatar
                          alt="Image"
                          className={classes.avatar}
                          src={getImage(p.product)}
                        ></Avatar>
                        {p.product.title}
                      </div>
                    </TableCell>

                    <TableCell>{p.product.upc}</TableCell>
                    <TableCell>{p.product.brand}</TableCell>
                    <TableCell>
                      {removeUnderscores(p.product.category)}
                    </TableCell>

                    {/*<TableCell>*/}
                    {/*  <div className={classes.tags}>*/}
                    {/*    {project.tags.map((tag) => (*/}
                    {/*      <Label*/}
                    {/*        color={tag.color}*/}
                    {/*        key={tag.text}*/}
                    {/*      >*/}
                    {/*        {tag.text}*/}
                    {/*      </Label>*/}
                    {/*    ))}*/}
                    {/*  </div>*/}
                    {/*</TableCell>*/}
                    {/*<TableCell align="right">*/}
                    {/*  <Button*/}
                    {/*    color="primary"*/}
                    {/*    component={RouterLink}*/}
                    {/*    size="small"*/}
                    {/*    to="/projects/1/overview"*/}
                    {/*    variant="outlined"*/}
                    {/*  >*/}
                    {/*    View*/}
                    {/*  </Button>*/}
                    {/*</TableCell>*/}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          // component={RouterLink}
          size="small"
          // to="/management/projects"
          variant="text"
        >
          See all
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </Button>
      </CardActions>
    </Card>
  );
}

TopSellingProducts.propTypes = {
  className: PropTypes.string,
};

export default TopSellingProducts;
