// Accepts product:
//  {
//             "id": 1,
//             "product": {
//                 "id": 1,
//                 "images": [
//                     {
//                         "image_url": "https://i5.walmartimages.com/asr/8264caca-5623-4c1e-b153-e417e2319e54_1.d8959b8a56ba4d77bb98a74f6e197cec.jpeg?odnHeight=450&odnWidth=450&odnBg=ffffff",
//                         "default": true
//                     },
//                     {
//                         "image_url": "http://images10.newegg.com/ProductImageCompressAll200/A0SD_1_20151116568881804.jpg",
//                         "default": false
//                     },
//                     {
//                         "image_url": "http://site.unbeatablesale.com/NSCD9435.JPG",
//                         "default": false
//                     },
//                     {
//                         "image_url": "http://c.shld.net/rpx/i/s/pi/mp/3670/7861430020?src=http%3A%2F%2Fmedia.mydoitbest.com%2FImageRequest.aspx%3Fsku%3D973502%26size%3D3&d=346541b2b94aef680bb8202240cbe2bbf79d58e3",
//                         "default": false
//                     },
//                     {
//                         "image_url": "http://c.shld.net/rpx/i/s/i/spin/image/spin_prod_ec_766271801",
//                         "default": false
//                     },
//                     {
//                         "image_url": "https://www.officedepot.com/pictures/us/od/sk/lg/508044_sk_lg.jpg",
//                         "default": false
//                     },
//                     {
//                         "image_url": "https://target.scene7.com/is/image/Target/GUEST_fee74de6-4652-4b18-a892-0036c3af8175?wid=1000&hei=1000",
//                         "default": false
//                     },
//                     {
//                         "image_url": "http://ct.mywebgrocer.com/legacy/productimagesroot/DJ/4/5294.jpg",
//                         "default": false
//                     }
//                 ],
//                 "created_at": "2019-11-15T00:05:00.657739-08:00",
//                 "updated_at": "2020-01-24T16:18:00.898429-08:00",
//                 "ean": 19000083425,
//                 "upc": 19000083425,
//                 "title": "Wrigley 7-oz Lifesavers 5 Flavor Gummi Snacks",
//                 "description": "Gummy candy featuring regular LifeSavers flavors; 5-flavors per bag: wild cherry, watermelon, green apple, blackberry, strawberry",
//                 "category": "CANDY",
//                 "brand": "Lifesavers",
//                 "model": "11651",
//                 "color": "Multi",
//                 "size": "",
//                 "dimension": "12 X 6 X 6 inches",
//                 "weight": "6 Pounds",
//                 "disabled": false
//             }
export default (product) => {
  const image_urls = product.images.filter((i) => {
    return i.default === true;
  });
  return image_urls.length > 0
    ? image_urls[0]['image_url']
    : product.images.length > 0
    ? product.images[0]['image_url']
    : '/images/products/empty_product_img.png';
};
