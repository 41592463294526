import React, { useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import AchiveIcon from '@material-ui/icons/ArchiveOutlined';

function ChartMoreButton({ onSelect, ...props }) {
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <Tooltip title="More options">
        <IconButton
          {...props}
          onClick={handleMenuOpen}
          ref={moreRef}
          size="small"
        >
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => onSelect(7)}>
          {/*<ListItemIcon>*/}
          {/*  <GetAppIcon />*/}
          {/*</ListItemIcon>*/}
          <ListItemText primary="7 days" />
        </MenuItem>
        <MenuItem onClick={() => onSelect(30)}>
          {/*<ListItemIcon>*/}
          {/*  <FileCopyIcon />*/}
          {/*</ListItemIcon>*/}
          <ListItemText primary="30 days" />
        </MenuItem>
        <MenuItem onClick={() => onSelect(60)}>
          {/*<ListItemIcon>*/}
          {/*  <PictureAsPdfIcon />*/}
          {/*</ListItemIcon>*/}
          <ListItemText primary="60 days" />
        </MenuItem>
        <MenuItem onClick={() => onSelect(90)}>
          {/*<ListItemIcon>*/}
          {/*  <PrintIcon />*/}
          {/*</ListItemIcon>*/}
          <ListItemText primary="90 days" />
        </MenuItem>
      </Menu>
    </>
  );
}

ChartMoreButton.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func,
};

export default memo(ChartMoreButton);
