import axios from '../utils/axios';

export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const SESSION_LOGIN_FAILED = 'SESSION_LOGIN_FAILED';

export const login = (values) => {
  return (dispatch) => {
    axios
      .post('/api/v1/login/', {
        phone_number: 'email_auth',
        email: values.email,
        password: values.password,
      })
      .then(
        (response) => {
          console.log(
            'response.status fjkhsdgfsdjkhfgsd jkhg s',
            response.status
          );
          if (response.status === 200) {
            dispatch({ type: SESSION_LOGIN, payload: response.data });
          }
        },
        (error) => {
          console.log(error);
          dispatch({ type: SESSION_LOGIN_FAILED });
        }
      );
  };
};

export const logout = () => (dispatch) =>
  dispatch({
    type: SESSION_LOGOUT,
  });
