import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;
const instance = axios.create({ baseURL: baseUrl });
// const instance = axios.create();

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function(error) {
    const originalRequest = error.config;
    // const history = useHistory();
    // console.log("baseUrl + '/api/v1/users/refresh_token: '" + baseUrl + '/api/v1/users/refresh_token')
    try {
      if (
        error.response &&
        error.response.status === 401 &&
        originalRequest.url === baseUrl + '/api/v1/users/refresh_token'
      ) {
        console.error(
          'Both access and refresh tokens are expired! Redirecting to login!'
        );
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        window.location.href = '/auth/login';
        return Promise.reject(error);
      }
    } catch (error) {
      return Promise.reject(error);
    }

    // console.log('originalRequest.url === baseUrl + \'/api/v1/login\' :' + originalRequest.url)
    // console.log('error.response: ' + error.response)
    if (originalRequest.url === baseUrl + '/api/v1/login/') {
      console.error('Wrong login');
      return Promise.reject(error);
    }

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      console.error('Access token has expired. Need to update...');
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refresh');
      if (refreshToken === null) {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        window.location.href = '/auth/login';
      }
      // console.warn("refreshToken: " + refreshToken)
      return instance
        .post('/api/v1/users/refresh_token', {
          refresh: refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            console.error('Successfully refreshed access token');
            localStorage.setItem('access', res.data.access);
            localStorage.setItem('refresh', res.data.refresh);
            instance.defaults.headers.common['Authorization'] =
              'Bearer ' + localStorage.getItem('access');
            return instance(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);

export default instance;
