import {
  SESSION_LOGIN,
  SESSION_LOGOUT,
  SESSION_LOGIN_FAILED,
} from 'src/actions/sessionActions';

const loggedIn = localStorage.getItem('access') != null;
const initialState = {
  loggedIn,
  user: {
    first_name: 'Slava',
    last_name: 'Chabanov',
    email: 'demo@devias.io',
    avatar: '/images/avatars/avatar_11.png',
    bio: 'Software Developer',
    role: 'USER', // ['GUEST', 'USER', 'ADMIN']
  },
  loginFailed: false,
};

const session = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_LOGIN: {
      if (action.payload) {
        if (action.payload.access) {
          console.log(action.payload.access);
          localStorage.setItem('access', action.payload.access);
          localStorage.setItem('refresh', action.payload.refresh);
        }
        action.payload.user.role = 'USER';
        state = {
          ...initialState,
          user: {
            ...initialState.user,
            ...action.payload.user,
          },
        };
        return {
          ...state,
          loggedIn: true,
        };
      } else
        return {
          ...initialState,
        };
    }

    case SESSION_LOGOUT: {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      return {
        ...state,
        loggedIn: false,
      };
    }

    case SESSION_LOGIN_FAILED: {
      console.log(
        'SESSION_LOGIN_FAILED SESSION_LOGIN_FAILED SESSION_LOGIN_FAILED'
      );
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      return {
        ...state,
        loggedIn: false,
        loginFailed: true,
        // user: {
        //     role: 'GUEST'
        // }
      };
    }

    default: {
      return state;
    }
  }
};

export default session;
