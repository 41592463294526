import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import GenericMoreButton from 'src/components/GenericMoreButton';
import Chart from './Chart';
import axios from '../../../utils/axios';
import ChartMoreButton from '../../../components/ChartMoreButton';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: '100%',
  },
}));

function ProductSalesOverTime({ className, ...rest }) {
  const classes = useStyles();
  const [days, setDays] = useState(7);
  const [data, setData] = useState({
    // thisYear: {
    //   data: [],
    //   labels: []
    // },
    // thisMonth: {
    //   data: [],
    //   labels: []
    // },
    thisWeek: {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
  });

  const onMenuSelect = (ddays) => {
    setDays(ddays);
  };

  useEffect(() => {
    let mounted = true;

    const fetchDeposits = () => {
      axios
        .get('/api/v1/data/get_daily_sales/?days=' + days)
        .then((response) => {
          if (mounted) {
            setData(response.data.data);
          }
        });
    };

    fetchDeposits();

    return () => {
      mounted = false;
    };
  }, [days]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={<ChartMoreButton onSelect={onMenuSelect} />}
        title={'Product Sales Last ' + days + ' Days'}
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
              data={data.thisWeek.data}
              labels={data.thisWeek.labels}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
}

ProductSalesOverTime.propTypes = {
  className: PropTypes.string,
};

export default ProductSalesOverTime;
